// @ts-nocheck

const init = () => {
  const account = document.querySelector('.m-account');
  const accountModals = account?.querySelectorAll('.m-account__modal') as NodeListOf<HTMLElement>;
  const toggleButtons = account?.querySelectorAll('[aria-expanded]') as NodeListOf<HTMLButtonElement>;
  const backButtons = account?.querySelectorAll('button.btn-back') as NodeListOf<HTMLButtonElement>;
  const countrySelects = account?.querySelectorAll('[data-address-country-select]') as NodeListOf<HTMLSelectElement>;
  const deleteAddressButtons = account?.querySelectorAll('.btn-delete-address') as NodeListOf<HTMLButtonElement>;
  const deleteAddressForms = account?.querySelectorAll('.m-account__delete-address') as NodeListOf<HTMLFormElement>;
  // const viewOrderLinks = account?.querySelectorAll('.btn-order-view') as NodeListOf<HTMLLinkElement>;

  toggleButtons?.forEach((button) => {
    button.addEventListener('click', (e: MouseEvent) => {
      e.preventDefault();

      const target = e.currentTarget as HTMLButtonElement;
      const modalId = target.getAttribute('aria-controls');
      const modal = document.getElementById(modalId);
      target?.setAttribute('aria-expanded', 'true');

      // close all other modals
      accountModals?.forEach((modal) => {
        modal?.classList.remove('is-active');
      });

      modal?.classList.add('is-active');
    });
  });
  
  backButtons?.forEach((button) => {
    button.addEventListener('click', ({ currentTarget }: MouseEvent) => {
      const target = currentTarget as HTMLElement;
      
      toggleButtons?.forEach((button) => {
        button?.setAttribute('aria-expanded', 'false');
      });

      accountModals?.forEach((modal) => {
        modal?.classList.remove('is-active');
      });
    });
  });

  deleteAddressButtons?.forEach((button) => {
    button.addEventListener('click', (e: MouseEvent) => {
      if (!confirm('Are you sure you want to delete this address?')) {
        e.preventDefault();
      }
    });
  });

  // deleteAddressButtons?.forEach((button) => {
  //   button.addEventListener('click', (e: MouseEvent) => {
  //     e.preventDefault();
  //     if (confirm('Are you sure you want to delete this address?')) {
  //       Shopify.postLink((e.currentTarget as HTMLElement).dataset.target, {
  //         parameters: {
  //           _method: 'delete',
  //           return_to: window.location.pathname,
  //         },
  //       });
  //     }
  //   });
  // });
  
  
  if (Shopify && Shopify.CountryProvinceSelector) {
    // eslint-disable-next-line no-new
    // new Shopify.CountryProvinceSelector('AddressCountryNew', 'AddressProvinceNew', {
    //   hideElement: 'AddressProvinceContainerNew',
    // });
    
    countrySelects?.forEach((select) => {
      const formId = select.dataset.formId;
      
      // eslint-disable-next-line no-new
      new Shopify.CountryProvinceSelector(`AddressCountry_${formId}`, `AddressProvince_${formId}`, {
        hideElement: `AddressProvinceContainer_${formId}`,
      });
    });
  }
};

document.addEventListener('DOMContentLoaded', init);

export default {
  init,
};